//React
import { useState, useContext, useEffect, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";

//DB
import * as localDb from "../../services/localDb";

//MUI
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import MapIcon from "@mui/icons-material/Map";
import Drawer from "@mui/material/Drawer";
import AddLocationIcon from "@mui/icons-material/AddLocation";

//Components
import CustomList from "../CustomList/CustomList";
import OverviewMap from "../OverviewMap/OverviewMap";
import DrawerHeaderBar from "../DrawerHeaderBar/DrawerHeaderBar.jsx";
import CreateNewActivity from "../TaskForms/CreateNewActivity";

import CreateNewSite from "../TaskForms/CreateNewSite";

//Context
import AppContext from "../../context/appContext";
import refDataPayloadById from "../../services/localRefData/refDataPayloadById.js";
import refDataByKey from "../../services/localRefData/refDataByKey.js";

export default function ActivityList() {
    //States
    const { activeFilter, setAppBarTitle, setNavBackTarget, setShowFilters, setFiltersConfig, setCurrentProgramConfig } = useContext(AppContext);
    const [event, setEvent] = useState();
    const [activities, setActivities] = useState();
    const [filteredActivities, setFilteredActivities] = useState();
    const [samplingAreas, setSamplingAreas] = useState();
    const [sites, setSites] = useState();
    const [showTaskList, setShowTaskList] = useState(false);
    const [showNewActivity, setShowNewActivity] = useState();
    const [showNewSite, setShowNewSite] = useState();
    const [showMap, setShowMap] = useState(false);

    //Context & Hooks
    const params = useParams();
    let [searchParams] = useSearchParams();

    const updateAppBar = useCallback(
        (title) => {
            if (sites && samplingAreas) {
                setAppBarTitle(title);
                setNavBackTarget("/");
                setShowFilters(true);
                setFiltersConfig({
                    mainFilter: { name: "SITIO", list: [...sites, { id: 999, name: "TODOS" }], default: 999 },
                    secondaryFilter: { name: "ESTRATO", list: [...samplingAreas, { id: 999, name: "TODOS" }], default: 999 },
                });
            }
        },
        [sites, samplingAreas, setAppBarTitle, setNavBackTarget, setShowFilters, setFiltersConfig]
    );

    function showNewActivityForm() {
        setShowNewActivity(true);
    }

    function showNewSiteForm() {
        setShowNewSite(true);
    }

    async function closeNewActivityForm() {
        let activities = await localDb.getMany("activities").then((result) => result.where({ monitoringEventId: event.id }).toArray());
        setActivities(activities);
        setShowNewActivity(false);
    }

    async function closeNewSiteForm() {
        setShowNewSite(false);
        let activities = await localDb.getMany("activities").then((result) => result.where({ monitoringEventId: event.id }).toArray());
        let sites = await localDb.getMany("sites").then((result) =>
            result
                .where({ farmId: event.farmId })
                .filter((farm) => !farm.isDeleted)
                .sortBy("name")
        );
        setActivities(activities);
        setSites(sites);
    }

    useEffect(() => {
        async function getEventById(id) {
            let event = await localDb.getOne("events", id);
            let farm = await localDb.getOne("farms", event.farmId);

            let activities = await localDb.getMany("activities").then((result) => result.where({ monitoringEventId: event.id }).sortBy("key"));
            let samplingAreas = await localDb.getMany("samplingAreas").then((result) =>
                result
                    .where({ farmId: farm.id })
                    .filter((samplingArea) => !samplingArea.isDeleted)
                    .sortBy("name")
            );
            let sites = await localDb.getMany("sites").then((result) =>
                result
                    .where({ farmId: farm.id })
                    .filter((site) => !site.isDeleted)
                    .sortBy("name")
            );

            let program = refDataPayloadById("programs", farm?.programId ? farm.programId : 99);
            let programConfig = refDataByKey("refProgramConfigs").find((pc) => pc.programId === program.id);

            setCurrentProgramConfig(programConfig);
            setEvent(event);
            setSamplingAreas(samplingAreas);
            setSites(sites);
            setActivities(activities);
            setFilteredActivities(activities);
        }
        getEventById(params.eventId);
    }, [params.eventId, activeFilter, setCurrentProgramConfig]);

    useEffect(() => {
        updateAppBar(event?.name);
    }, [event, updateAppBar]);

    useEffect(() => {
        if (searchParams.get("showList")) {
            setShowTaskList(true);
        }
    }, [searchParams]);

    useEffect(() => {
        if (activities && activeFilter) {
            let matcherMain = new RegExp(activeFilter.mainFilter === 999 ? ".*" : activeFilter.mainFilter);
            let matcherSecondary = new RegExp(activeFilter.secondaryFilter === 999 ? ".*" : activeFilter.secondaryFilter);
            let filteredAct = activities.filter((activity) => matcherMain.test(activity.monitoringSiteId) && matcherSecondary.test(activity.samplingAreaId));
            setFilteredActivities(filteredAct);
        }
    }, [activities, activeFilter]);

    return (
        <div>
            {event && filteredActivities && samplingAreas && activities && (
                <Stack display='flex' direction='column'>
                    <Stack display='flex' direction='row' alignItems={"center"} justifyContent={"space-around"}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 0.5 }}>
                            <Button startIcon={<AddLocationIcon />} variant='text' onClick={() => showNewSiteForm()}>
                                SITIO
                            </Button>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 0.5 }}>
                            <Button startIcon={<AddIcon />} variant='text' onClick={() => showNewActivityForm()}>
                                ACTIVIDAD
                            </Button>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 0.5 }}>
                            <Button startIcon={<MapIcon />} variant='text' onClick={() => setShowMap(true)}>
                                MAPA
                            </Button>
                        </Box>
                    </Stack>
                    <Divider />
                    <CustomList title={""} listData={filteredActivities} eventId={event.id} showTaskList={showTaskList} />
                    {showNewActivity && <CreateNewActivity show={showNewActivity} handleClose={closeNewActivityForm} samplingAreas={samplingAreas} sites={sites} event={event} />}
                    {showNewSite && <CreateNewSite show={showNewSite} handleClose={closeNewSiteForm} event={event} samplingAreas={samplingAreas} sites={sites} />}
                    {showMap && (
                        <Drawer PaperProps={{ sx: { height: "100vh" } }} anchor='bottom' open={showMap} onClose={() => setShowMap(false)}>
                            <Stack display='flex' direction='column' flexGrow={0} justifyItems='flex-start' alignContent={"space-between"} sx={{ height: "100vh", overflow: "hidden" }}>
                                <DrawerHeaderBar height={"50px"} textColor='#FFFF' bgColor='#3F3938' title='Vista General' handleClose={() => setShowMap(false)} />
                                <OverviewMap height={"calc(100vh - 45px)"} enableLocation={true} allowZoom={true} allowDonwload={false} eventId={params.eventId} />
                            </Stack>
                        </Drawer>
                    )}
                </Stack>
            )}
        </div>
    );
}
