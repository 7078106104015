import { useEffect, useState } from "react";

//Helpers
import statusColorCoder from "../../services/helpers/statusColorCoder";
import refDataById from "../../services/localRefData/refDataById";

//Components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ActivitySummary from "../ActivitySummary/ActivitySummary";

//Icons
import ChevronRight from "@mui/icons-material/ChevronRight";
import { appStore } from "../../services/lfStorage/lfStorage";
import { Chip } from "@mui/material";
import moment from "moment";

export default function CustomList({ showTaskList, listData, title }) {
    const [drawerStatus, setDrawerStatus] = useState(false);
    const [currentItem, setCurrentItem] = useState();

    async function handleListItemClick(itemData) {
        await appStore.set("lastActivity", itemData);
        setCurrentItem(itemData);
        openDrawer();
    }
    function openDrawer() {
        setDrawerStatus(true);
    }
    function closeDrawer() {
        setDrawerStatus(false);
    }

    useEffect(() => {
        async function getLastActivity() {
            let itemData = await appStore.get("lastActivity");
            setCurrentItem(itemData);
            openDrawer();
        }
        if (showTaskList) {
            getLastActivity();
        }
    }, [showTaskList]);

    return (
        <Box>
            <List>
                {listData.length ? (
                    listData.map((item, index) => (
                        <div key={index}>
                            <ListItem
                                onClick={item.isDeleted ? null : () => handleListItemClick(item)}
                                secondaryAction={
                                    item.isDeleted ? (
                                        <Chip color='error' disabled sx={{ height: "22px" }} variant='filled' size='small' label={`Eliminado ${moment(item.updatedAt).format("DD/MM/YYYY")}`} />
                                    ) : (
                                        <IconButton>
                                            <ChevronRight variant='outlined' />
                                        </IconButton>
                                    )
                                }
                            >
                                <ListItemText
                                    primary={item.key.toUpperCase()}
                                    secondary={refDataById("taskStatus", item.taskStatusId) + ` -  ${item.completedTasks > 0 ? ((item.completedTasks / item.totalTasks) * 100).toFixed(0) : 0}%`}
                                    secondaryTypographyProps={{ color: statusColorCoder(item.taskStatusId) }}
                                />
                            </ListItem>
                        </div>
                    ))
                ) : (
                    <Typography align='center' sx={{ p: 1 }} variant='body1'>
                        No items to display
                    </Typography>
                )}
                {currentItem && <ActivitySummary open={drawerStatus} activity={currentItem} handleClose={() => closeDrawer()} />}
            </List>
        </Box>
    );
}
