import endpoints from "./endpoints.js";
//import compareObjects from '../helpers/compareObjects.js';
import * as localDb from "../localDb/index.js";
import postApiData from "./postApiData.js";
import moment from "moment/moment.js";

export default async function downloadMonitoringData(user) {
    try {
        let db = await localDb.getDb();
        let localEvents = await localDb.getMany("events").then((result) => result.toArray());
        let localSites = await localDb.getMany("sites").then((result) => result.toArray());
        let localSitesIds = localSites.map((s) => s.id);

        const { payload: monitoringData } = await postApiData(endpoints.syncDownloadNew, {
            assignedTo: user["/hubId"] === "all" || user["/hubId"].includes("all") ? null : user.name,
            localEvents: [...localEvents.map((e) => e.id)],
        });

        await db.transaction("rw", ["farms", "samplingAreas", "sites", "paddocks"], async () => {
            //save farm data
            await Promise.all([
                db.farms.bulkPut(monitoringData.farms),
                db.samplingAreas.bulkPut(monitoringData.samplingAreas),
                db.paddocks.bulkPut(monitoringData.paddocks),
                db.sites.bulkPut(monitoringData.monitoringSites.filter((site) => !localSitesIds.includes(site.id))),
            ]);
        });

        await db.transaction("rw", ["events", "activities", "tasks", "pictures"], async () => {
            //save event data
            await Promise.all([
                db.events.bulkPut(monitoringData.newEvents),
                db.activities.bulkPut(monitoringData.monitoringActivity),
                db.tasks.bulkPut(monitoringData.monitoringTasks),
                db.pictures.bulkPut(monitoringData.monitoringPictures),
            ]);
        });

        if (monitoringData.deletedEvents && monitoringData.deletedEvents.length) {
            let deletedEventsToUpdate = monitoringData.deletedEvents.map((deletedEvent) => ({
                key: deletedEvent,
                changes: {
                    isDeleted: true,
                    updatedAt: moment.utc().format(),
                },
            }));
            await db.events.bulkUpdate(deletedEventsToUpdate);
        }
        if (monitoringData.deletedMonitoringSiteIds && monitoringData.deletedMonitoringSiteIds.length) {
            await db.transaction("rw", ["changelog", "sites", "activities", "tasks", "pictures"], async (db) => {
                for (const monitoringSiteId of monitoringData.deletedMonitoringSiteIds) {
                    // get collections for each related entity
                    const tasksToDelete = await db.tasks.where({ monitoringSiteId }).and((task) => !task.isDeleted);
                    const sitePicturesToDelete = await db.pictures.where({ entityId: monitoringSiteId }).and((picture) => !picture.isDeleted);
                    const activitiesToDelete = await db.activities.where({ monitoringSiteId }).and((activity) => !activity.isDeleted);
                    const sitesToDelete = await db.sites.where({ id: monitoringSiteId }).and((site) => !site.isDeleted);

                    // find related pictures and set isDeleted = true
                    const tasksToDeleteArray = await tasksToDelete.toArray();
                    for (const task of tasksToDeleteArray) {
                        await db.pictures.where({ entityId: task.id, entity: "task" }).modify({ isDeleted: true });
                    }

                    // set deleted flag for related entities
                    await tasksToDelete.modify({ isDeleted: true });
                    await sitePicturesToDelete.modify({ isDeleted: true });
                    await activitiesToDelete.modify({ isDeleted: true });
                    await sitesToDelete.modify({ isDeleted: true });

                    // remove pending changelog entries for deleted entities
                    const allEntities = await Promise.all([tasksToDelete.toArray(), sitePicturesToDelete?.toArray(), activitiesToDelete.toArray(), sitesToDelete.toArray()]);
                    for (const entity of allEntities.flat()) {
                        await db.changelog.where({ syncStatus: "not-synced", entityId: entity.id, entity: entity.entity }).delete();
                    }
                }
            });
        }
        return "Success";
    } catch (error) {
        console.error(error);
        throw error;
    }
}
