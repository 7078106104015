import { useEffect, useState, useContext, useCallback, memo } from "react";
import moment from "moment";
import debounce from "debounce";
import { FixedSizeList, areEqual } from "react-window";

//Auth0
import { useAuth0, OAuthError } from "@auth0/auth0-react";

//Context
import AppContext from "../../context/appContext";

//db
import * as localDb from "../../services/localDb";
import { LocalDbAuthenticationError } from "../../services/localDb";

//Components
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { TextField, useMediaQuery } from "@mui/material";

//Icons
import PlayCircleOutlined from "@mui/icons-material/PlayCircleOutlineOutlined";
import CircleIcon from "@mui/icons-material/Circle";

//Services
import refDataById from "../../services/localRefData/refDataById";
import statusColorCoder from "../../services/helpers/statusColorCoder.js";
import { ruutsApi } from "../../services/ruutsApi";
import EventOverview from "../EventOverview/EventOverview";
import { appStore } from "../../services/lfStorage/lfStorage";

import { SyncIndicator } from "./SyncIndicator";
import { Search } from "@mui/icons-material";
import { DeleteIndicator } from "./DeleteIndicator.jsx";

export default function MyEvents() {
    const { setShowFilters, currentEvent, setCurrentEvent, setLoadingSomething, isOnline, setAlert, setNotify, setCurrentUser, currentUser, storageFreeze, storageData, windowWidth, windowHeight } =
        useContext(AppContext);
    const showProgramTag = useMediaQuery("(min-width:300px)");
    const [myEvents, setEvents] = useState();
    const [farms, setFarms] = useState();
    const [searchString, setSearchString] = useState(null);
    const [currentFarm, setCurrentFarm] = useState();
    const [modalStatus, setModalStatus] = useState(false);
    const { getAccessTokenSilently, loginWithRedirect, error, isLoading, user, isAuthenticated } = useAuth0();

    const searchEvents = (eventData) => {
        if (searchString && searchString.length) {
            return eventData.name.toLowerCase().includes(searchString.toLowerCase());
        }
        return true;
    };

    const handleSearchInput = debounce((value) => setSearchString(value), 300);

    const loginFlow = useCallback(async () => {
        try {
            await getAccessTokenSilently({ detailedResponse: true });
        } catch (e) {
            console.error(e);
            if (e instanceof OAuthError && window.navigator.onLine) {
                await loginWithRedirect();
            }
        }
    }, [loginWithRedirect, getAccessTokenSilently]);

    async function handleListItemClick(eventData, farmData) {
        try {
            setCurrentEvent(eventData);
            setCurrentFarm(farmData);
            if (eventData.tags && eventData.tags instanceof Map) {
                eventData.tags.set("nuevo", false);
                await localDb.update("events", eventData.id, { tags: eventData.tags }, false);
            }
        } catch (e) {
            console.error("Unable to set tags:", e.message);
        } finally {
            toggleModal({ reload: false });
        }
    }

    async function handleUpload(changes, eventData, element) {
        try {
            if (!isAuthenticated) {
                return setAlert({
                    message: "Necesita iniciar sesión para sincornizar datos al servidor. Asegúrese de tener conectividad. ¿Desea continuar?",
                    severity: "warning",
                    show: true,
                    actionButtonTitle: "CONTINUAR",
                    action: async () => await loginFlow().then(async () => await ruutsApi.syncEventDataUp(changes, eventData.id, element)),
                });
            }
            ruutsApi.syncEventDataUp(changes, eventData.id, element);
        } catch (e) {
            console.error(e);
            setNotify({
                message: `Error al sincronizar los datos al servidor. Si el error persiste, contacte al administrador. Mensage: ${e.message}`,
                severity: "error",
                show: true,
            });
        }
    }

    function toggleModal({ reload }) {
        setModalStatus(!modalStatus);
        if (reload) {
            getLocalEvents();
        }
    }

    function getProgramTag(programId) {
        if (programId === 99) return null;
        let name = refDataById("programs", programId);
        if (name) return <Chip variant='outlined' color='secondary' label={name} size='small' />;
        return null;
    }

    useEffect(() => {
        async function setLocalUser() {
            await appStore.set("currentUser", user);
        }
        if (!isLoading && !error && user) {
            setLocalUser();
            setCurrentUser(user);
        }
    }, [user, isLoading, error, setCurrentUser]);

    useEffect(() => {
        async function getEventsFromDb() {
            try {
                if (isOnline) {
                    let currentUser = await appStore.get("currentUser");
                    console.log("Online: getting events from DB");
                    if (!isAuthenticated) {
                        if (currentUser) {
                            setAlert({
                                message: "Necesita iniciar sesión para poder descargar eventos nuevos. Asegúrese de tener conectividad. ¿Desea iniciar sesión ahora?",
                                severity: "warning",
                                show: true,
                                actionButtonTitle: "Iniciar sesión",
                                cancelButtonTitle: "Seguir trabajando",
                                action: async () => await loginFlow(),
                                cancelAction: () => {},
                            });
                        } else {
                            console.log("user never authenticated");
                            loginFlow();
                        }
                    } else {
                        setLoadingSomething(true);
                        await ruutsApi.downloadMonitoringData(user);
                        await ruutsApi.getReferenceData();
                        let events = await localDb.getMany("events").then((result) => result.toArray());
                        let farms = await localDb.getMany("farms").then((result) => result.toArray());
                        setFarms(farms);
                        setEvents(events);
                        setLoadingSomething(false);
                    }
                }
            } catch (e) {
                setLoadingSomething(false);
                setNotify({
                    message: `Error al descargar datos. Si el error persiste, contacte al administrador (${e.message})`,
                    severity: "error",
                    show: true,
                });
            }
        }
        if (!isLoading && !error) {
            if (!storageFreeze) {
                getEventsFromDb();
            }
        }
    }, [setAlert, setNotify, loginFlow, setLoadingSomething, isOnline, isAuthenticated, user, storageFreeze, isLoading, error]);

    useEffect(() => {
        if (storageFreeze) {
            if (storageData.colorCode === "warning")
                setAlert({
                    severity: "warning",
                    show: true,
                    message: `El espacio utilizado es de ${storageData.usagePct} %. Se recomienda liberar espacio en su dispositivo.`,
                });
            if (storageData.colorCode === "error")
                setAlert({
                    show: true,
                    severity: "error",
                    message: `El espacio utilizado es de ${storageData.usagePct} %. Se encuentra por encima del lìmite recomendado. Libere espacio en su dispositivo para seguir trabajando. No borre los datos del navegador a menos que haya sincornizado todos los eventos con el servidor.`,
                });
        }
    }, [storageData, storageFreeze, setAlert]);

    const getLocalEvents = useCallback(async () => {
        try {
            let events = await localDb.getMany("events").then((result) => result.toArray());
            let farms = await localDb.getMany("farms").then((result) => result.toArray());
            setFarms(farms);
            setEvents(events);
        } catch (error) {
            if (error instanceof LocalDbAuthenticationError) {
                await loginFlow();
            }
        }
    }, [loginFlow]);

    useEffect(() => {
        getLocalEvents();
        setShowFilters(false);
    }, [setShowFilters, currentUser, loginFlow, getLocalEvents]);

    useEffect(() => {
        return handleSearchInput.clear();
    }, []);

    const Row = memo(({ data, index, style }) => {
        const eventData = data[index];
        const [farm] = farms?.filter((farm) => farm.id === data[index]?.farmId);
        return (
            <ListItem divider key={index} sx={{ ...style }}>
                <Grid container width={"100%"} pb={1}>
                    <Grid item xs={9} sm={9}>
                        <Stack overflow={"hidden"} spacing={1} direction={"column"} height={"100%"} display='flex' alignItems={"flex-start"} justifyContent={"space-between"}>
                            <Box display='flex' width={"auto"} alignItems={"center"} justifyContent={"center"}>
                                {eventData.tags instanceof Map && eventData.tags.get("nuevo") && <CircleIcon ml={0.5} color='primary' sx={{ fontSize: "12px" }} />}
                                <Typography
                                    sx={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        ml: 0.5,
                                    }}
                                    component='div'
                                    variant='h6'
                                >
                                    {eventData ? eventData.name.toUpperCase() : ""} ({farm ? farm.province : ""})
                                </Typography>
                            </Box>
                            <Stack pl={0.5}>
                                <Typography componet='div' variant='body' color={statusColorCoder(eventData.taskStatusId)}>
                                    {refDataById("taskStatus", eventData.taskStatusId).toLowerCase()}
                                </Typography>
                                <Typography variant='body2'> {moment.utc(eventData.date).format("YYYY-MM-DD")}</Typography>
                            </Stack>
                            {!eventData.isDeleted ? <SyncIndicator eventData={eventData} handleUpload={handleUpload} /> : <DeleteIndicator eventData={eventData} />}
                        </Stack>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Stack overflow={"hidden"} width={"100%"} direction={"column"} height={"100%"} display='flex' alignItems={"flex-end"} justifyContent={"space-between"}>
                            <Stack spacing={1} direction={"row"}>
                                {showProgramTag && farm?.programId ? getProgramTag(farm.programId) : getProgramTag(99)}
                            </Stack>
                            <IconButton sx={{ padding: 0, margin: 0 }} color='primary' onClick={() => handleListItemClick(eventData, farm)}>
                                <PlayCircleOutlined sx={{ fontSize: "50px" }} />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </ListItem>
        );
    }, areEqual);

    return (
        myEvents &&
        farms && (
            <Box className='event-list'>
                <Box>
                    <TextField
                        fullWidth
                        variant='outlined'
                        placeholder='Buscar por nombre del establecimiento'
                        InputProps={{
                            type: "search",
                            endAdornment: <Search color='primary' />,
                        }}
                        onChange={(e) => handleSearchInput(e.target.value)}
                    />
                </Box>
                <FixedSizeList
                    height={windowHeight - 300}
                    width={windowWidth}
                    itemCount={myEvents.filter(searchEvents).length}
                    overscanCount={1}
                    itemSize={140}
                    itemData={myEvents.filter(searchEvents)}
                    itemKey={(index, data) => data[index].id}
                >
                    {Row}
                </FixedSizeList>
                {currentEvent && <EventOverview handleClose={toggleModal} eventData={currentEvent} farm={currentFarm} show={modalStatus} />}
            </Box>
        )
    );
}
