import * as stores from "./stores.js";

async function clearPaddocks({ transaction }) {
    await transaction.table("paddocks").clear();
}

export const v10 = {
    versionNumber: 10,
    stores: stores.v9,
    migrations: [clearPaddocks],
};
