//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//MUI
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

//Components
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";
import refDataByKey from "../../../services/localRefData/refDataByKey.js";
import { useContext } from "react";
import AppContext from "../../../context/appContext.js";

export default function TaskForm({ handleCameraInput, watchPosition, stopWatch, editMode, plannedLocation, pictures, monitoringSite, destination }) {
    const { setValue, control } = useFormContext();
    const { locale } = useContext(AppContext);

    const picKey = `CAMBIO-SITIO-${monitoringSite.name}`;

    return (
        <Stack spacing={2}>
            <Controller
                control={control}
                name='plannedLocation'
                defaultValue={monitoringSite.plannedLocation}
                readOnly={true}
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                        name='plannedLocation'
                        type={"text"}
                        label='Coordenadas originales'
                    />
                )}
            />
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={destination}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={true}
                readOnly={true}
            />
            <FormControl variant='outlined'>
                <InputLabel id='reason-select-label'>Motivo</InputLabel>
                <Controller
                    control={control}
                    name='locationMovedReasonId'
                    defaultValue={0}
                    render={({ field }) => (
                        <Select
                            {...field}
                            MenuProps={{ sx: { zIndex: 10000 } }}
                            label='Motivo'
                            name='locationMovedReasonId'
                            fullWidth
                            labelId='reason-select-label'
                            onChange={(_, change) => {
                                field.onChange(change.props.value);
                            }}
                            value={field.value}
                        >
                            {refDataByKey("locationMovedReasons").map((reason) => (
                                <MenuItem key={reason.id} value={reason.id}>
                                    {reason[locale]}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
            <Controller control={control} name='comments' defaultValue={""} render={({ field }) => <TextField {...field} fullWidth multiline name='comments' type={"text"} label='Comentarios' />} />

            <Stack display='flex' flexWrap={"wrap"} direction='row' alignItems='center' justifyContent='space-around'>
                <PhotoInput
                    width='150'
                    height='150'
                    handleCameraFile={handleCameraInput}
                    picture={pictures.find((pic) => pic.key === picKey + "-1")}
                    pictureKey={pictures.find((pic) => pic.key === picKey)?.key || picKey + "-1"}
                    pictureIndex={pictures.findIndex((pic) => pic.key === picKey + "-1") >= 0 ? pictures.findIndex((pic) => pic.key === picKey + "-1") : 0}
                    pictureId={pictures.find((pic) => pic.key === picKey + "-1")?.id || null}
                />
                <PhotoInput
                    width='150'
                    height='150'
                    handleCameraFile={handleCameraInput}
                    picture={pictures.find((pic) => pic.key === picKey + "-2")}
                    pictureKey={pictures.find((pic) => pic.key === picKey + "-2")?.key || picKey + "-2"}
                    pictureIndex={pictures.findIndex((pic) => pic.key === picKey + "2") >= 0 ? pictures.findIndex((pic) => pic.key === picKey + "-2") : 1}
                    pictureId={pictures.find((pic) => pic.key === picKey + "-2")?.id || null}
                />
            </Stack>
        </Stack>
    );
}
