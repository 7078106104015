import moment from "moment";
import savePictures from "../helpers/savePictures";
import * as localDb from "../localDb";
import { latLngToArray } from "../geolocationScripts/latLngToArray";

export async function rejectSite({ data, monitoringSite, pictures, currentUser, task, setAlert, setPointShuffled }) {
    try {
        await localDb.update(
            "sites",
            monitoringSite.id,
            {
                locationConfirmed: false,
                locationMoved: true,
                locationMovedReasonId: data.locationMovedReasonId,
                locationMovedComments: data.comments,
                fieldRelocationMethodId: 0, // MDC
                backupLocation: latLngToArray(data.actualLocation),
                actualLocation: latLngToArray(data.actualLocation),
            },
            true
        );
        await localDb.update(
            "tasks",
            task.id,
            {
                plannedLocation: latLngToArray(data.actualLocation),
                updatedBy: currentUser.userName,
                updatedAt: moment().format("YYYY-MM-DD HH:mm:ssZ"),
            },
            true
        );
        if (pictures && pictures.length) {
            await savePictures(currentUser.userName, task.monitoringEventId, "site", monitoringSite.id, pictures);
        }
        setAlert({ show: true, message: "Sitio guardado con éxito", severity: "success" });
        setPointShuffled(false);
    } catch (error) {
        throw new Error(`No se pudo guardar el nuevo sitio: ${error.message}`);
    }
}
